<template>
	<div>
		<b-input-group v-if="!edit_horse_name">
			<b-input-group-append>
				<span class="input-group-text" @click="editHorseName">{{ horse_nom }}<font-awesome-icon class="ml-2" :icon="['far', 'pen']" /></span>
			</b-input-group-append>
		</b-input-group>
		<e-select v-else
			track-by="id"
			label="label"
			v-model="searchValue"
			group-label="label"
			group-values="values"
			:group-select="false"
			:options="options"
			:placeholder="$t('global.rechercher')"
			:loading="isLoading"
			:sortable="false"
			:internal-search="false"
			preserve-search
			reset-after
			@search-change="onSearch"
			@select="searchHorse"
			ref="input"
		>
			<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
			<template slot="noOptions">{{ $t('global.list_empty') }}</template>
		</e-select>
	</div>
</template>

<script>
import _debounce from 'lodash/debounce'
import Common from '@/assets/js/common.js'
import Horse from '@/mixins/Horse.js'

export default {
	mixins: [Horse],
	props:['sexe', 'horse_nom', 'sire_encode'],
    data() {
        return {
			options: [
				{
					label: this.$t('global.in_groomy'),
					values: []
				},
				{
					label: this.$t('global.not_in_groomy'),
					values: []
				},
			],
			searchValue: null,
			isLoading: false,
			edit_horse_name: false,
			debouncedSearch: _debounce(this.search, 300),
			horse_sexe: null
		}
	},
	mounted() {
		this.init_component()
	},
	methods: {
		init_component() {
			if(this.sexe == 1) {
				this.horse_sexe = 'M'
			} else {
				this.horse_sexe = 'F'
			}
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			this.isLoading = true

			// Horses
			const horsePromise = this.$storage.db.t('horse').then(table => {
				return table.where('horse_nom').startsWithIgnoreCase(search_val)
					.or('horse_sire').startsWithIgnoreCase(search_val)
					.or('horse_transpondeur').startsWithIgnoreCase(search_val)
					.and(horse => horse.horse_sexe == this.horse_sexe)
					.limit(20)
					.sortBy('horse_nom')
			})
			.then(horses => {
				this.options[0].values = horses.map(horse => {
					return {
						id: horse.horse_id,
						groomy: 1,
						label: horse.horse_nom
					}
				})
			})
			
			this.options[1].values = [{
				id: 0,
				groomy: 0,
				label: search_val
			}]

			// Ajout des résultats au e-select
			await Promise.all([horsePromise])

			this.isLoading = false
		},
		async searchHorse(option) {
			this.internalHorseNom = option.label

			let parent_horse = await this.getHorseById(option.id)

			let sireencode = null

			if(parent_horse && option.groomy){
				if(parent_horse.horse_sire && parent_horse.horse_sire !== "") {
					sireencode = Common.get_sire_encode(parent_horse.horse_sire)
				}
				else {
					sireencode = '_' + option.id
				}
			}
			else {
				sireencode = null
			}

			this.internalSireEncode = sireencode

			this.edit_horse_name = false
		},
		editHorseName() {
			this.edit_horse_name = true
		}
	},
	computed: {
		internalHorseNom: {
			get() {
				return this.horse_nom
			},
			set(val) {
				this.$emit('update:horse_nom', val)
			}
		},
		internalSireEncode: {
			get() {
				return this.sire_encode
			},
			set(val) {
				this.$emit('update:sire_encode', val)
			}
		}
	}
}
</script>
